@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-color: #3c3c3b;
  --background-abu-abu: #f6f6f6;
  --background-cokelat-muda: #a3937a;
  --light-color: #7d7d7d;
}
* {
  margin: 0;
  padding: 0;
}
body {
  background: var(--main-color);
  font-family: 'Cairo', sans-serif;
  font-size: 12px;
}
a {
  color: #000;
  text-decoration: none;
}
.container {
  width: 1300px;
  margin: auto;
}
.bg-abuabu {
  background: var(--background-abu-abu);
}
.bg-cokelat {
  background: var(--background-cokelat-muda);
}
.bg-putih {
  background: #f6f6f6;
}
.btn-car-order {
  color: #fff;
  background: var(--background-cokelat-muda);
}
.color-coklat-muda {
  color: var(--background-cokelat-muda);
}
header {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 20px 0;
}
header.fixed {
  position: fixed;
  background: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid #efefef;
  z-index: 666;
  backdrop-filter: blur(10px);
}
header .header-l2 {
  display: flex;
}
header .menu {
  display: flex;
  align-items: center;
  margin-left: auto;
}
header .menu li {
  list-style: none;
  margin: 0 0 0 25px;
}
header .menu li a {
  padding: 10px;
  color: var(--main-color);
  border-radius: 5px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
}
header .menu li a.solid {
  background: var(--main-color);
  color: #fff;
}
header .burger {
  width: 20px;
  height: 15px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  margin-left: auto;
  cursor: pointer;
}
header .burger span {
  width: 100%;
  height: 3px;
  background: var(--main-color);
  display: block;
}
.home {
}
.home .banner {
  width: 100%;
  height: 552px;
}
.home .banner img {
  width: 100%;
  height: 552px;
  object-fit: cover;
}
footer {
  color: white;
  padding: 20px 20px 120px;
  font-size: 14px;
}
footer .logo {
  margin-right: 40px;
  align-self: center;
}
footer .alamat {
  margin: 0 20px;
}
footer .alamat h5 {
  font-weight: 700;
  font-size: 16px;
}
footer .alamat p {
  font-weight: 100;
  line-height: 18px;
}
footer .copyrights {
  color: #a3937a;
}
footer .footer-l2 {
  display: flex;
}
footer .footer-l2 .copyrights {
  flex-shrink: 0;
  width: 360px;
  align-self: center;
  text-align: right;
  font-size: 16px;
  font-weight: 300;
}

.services {
  padding: 20px 0;
}
.services nav {
  text-align: center;
}
.services button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 169%;
  background: var(--background-cokelat-muda);
  border-radius: 8px;
  color: #fff;
  padding: 0 20px;
}
.services button:hover {
  background: #3c3c3b;
}
.service-l2 h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}
.service-l2 h2:after {
  content: ' ';
  position: absolute;
  width: 45px;
  height: 2px;
  bottom: 10px;
  left: 0;
  z-index: 1;
  background: var(--main-color);
}

.modal-menu {
  background: var(--main-color);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 667;
}
.modal-menu .close {
  text-align: right;
}
.modal-menu .close button {
  background: transparent;
  padding: 20px;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.modal-menu .close button:hover {
  color: #ccc;
}
.modal-menu nav {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px 60px;
}
.modal-menu nav a {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 169%;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: white;
  border-bottom: 0.5px solid #fff;
  padding: 10px;
}
.modal-menu nav a:hover {
  color: antiquewhite;
}

.car-list {
  padding: 0 0 50px;
}
.car-list .category {
  padding: 15px 0 30px;
}
.car-list .category button {
  background: transparent;
  border: 1px solid #000;
  cursor: pointer;
  padding: 3px 20px;
  border-radius: 5px;
  margin: 0 15px 0 0;
}
.car-list .category button:hover,
.car-list .category button.active {
  background: #000;
  color: #fff;
}
.car-list h2 {
  font-weight: 700;
  font-size: 48px;
}
.car-list .car {
  /* display: flex;
  flex-wrap: wrap;
  margin: -10px; */
}
.car-list .car .item {
  /* width: 438px; */
}
.car-list .drive {
  font-weight: 700;
  color: #7d7d7d;
}
.item-p {
  background: #fff;
  /* margin: 10px; */
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  display: grid;
  place-content: center;
  padding: 0 20px;
  height: 535px;
}
.item-p .foto {
  height: 207px;
}
.item-p .foto img {
  width: 100%;
  height: 207px;
  object-fit: contain;
}
.item-p nav {
  display: flex;
  margin: 5px 0 0;
}
.item-p nav button {
  background: transparent;
  border: 1px solid var(--background-cokelat-muda);
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px;
  color: var(--background-cokelat-muda);
  cursor: pointer;
}
.item-p nav button:hover {
  margin-top: 1px;
  border-bottom-width: 3px;
}
.item-p nav button:active {
  margin-top: 3px;
  border-bottom-width: 1px;
}
.item-p .desc {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.item-p .desc .merek {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}
.item-p .desc .label-harga {
  font-weight: 300;
  font-size: 18px;
}
.item-p .desc .harga {
  font-weight: 700;
  font-size: 40px;
}
.item-p .desc p {
  color: var(--background-cokelat-muda);
  font-weight: 300;
  font-size: 12px;
  padding: 0 35px;
  line-height: 16px;
}

.layanan {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: 300;
  padding: 20px 0;
  color: #fff;
}
.layanan .list {
  display: flex;
  align-items: center;
  margin: 0 50px;
}
.layanan .list .icon {
  margin-right: 20px;
}

.modal-contact-bg {
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-contact-bg .modal-contact {
  background: #fff url('./assets/contact/contact.png') no-repeat;
  background-position: left center;
  border-radius: 10px;
  width: 930px;
  height: 460px;
  display: grid;
  place-content: center end;
  position: relative;
}
.modal-contact-bg .modal-contact .desc {
  width: 300px;
  height: 100%;
  margin-right: 50px;
}
.modal-contact-bg .modal-contact .close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 16px;
  cursor: pointer;
}
.modal-contact-bg .modal-contact h2 {
  font-weight: 700;
  font-size: 28px;
}
.wa {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.wa-ig {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
.wa-ig.contect {
  flex-direction: column;
}
.wa-ig.contect a {
  width: 250px;
  margin: 5px 0;
  padding: 10px 40px;
}
.wa-ig a {
  font-weight: 700;
  font-size: 20px;
  background-color: var(--main-color);
  border-radius: 30px;
  padding: 5px 40px;
  display: flex;
  align-items: center;
  color: #fff;
  margin: 10px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.wa-ig a img {
  margin-right: 10px;
}
.modal-contact-bg .modal-contact p {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  color: var(--background-cokelat-muda);
}

.overview {
  padding: 50px 0 30px;
}
.overview .overview-l2 {
  display: flex;
}
.overview .about {
  flex-shrink: 0;
  width: 571px;
  height: 540px;
  background: url('./assets/images/rent.jpg');
  border: 5px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-right: 40px;
}
.overview .about .about-l2 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 82.48%);
  height: 100%;
  display: grid;
  place-content: end;
}
.overview .about .about-l2 .desc {
  color: #fff;
  padding: 30px 50px;
}
.overview h1 {
  font-size: 36px;
  font-weight: 700;
}
.overview h2 {
  font-size: 24px;
  line-height: 169%;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  border-bottom: 0.5px solid #000;
  padding: 0 20px 20px;
  margin: 0 0 20px;
}
.overview .about p {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}
.overview .about p.wedding {
  color: #ffc700;
  position: relative;
  padding: 20px 0 70px;
}
.overview .about p.wedding:after {
  content: ' ';
  background: #ffc700;
  position: absolute;
  bottom: 45px;
  left: 0;
  width: 85px;
  height: 2px;
  z-index: 10;
}
.overview ul li {
  display: flex;
  margin: 0 0 33px;
}
.overview ul li .ico {
  margin-right: 20px;
  width: 55px;
  height: 55px;
  flex-grow: 0;
  flex-shrink: 0;
}
.overview ul li .desc {
  color: var(--background-cokelat-muda);
  font-size: 16px;
  line-height: 22px;
}
.overview ul li h3 {
  color: black;
  font-weight: 700;
  font-size: 18px;
  margin: 0 0 10px;
}

.testimoni {
  background: #f6f6f6;
  min-height: 600px;
  position: relative;
}
.testimoni .banner {
  /* background: #fff url('./assets/images/testimoni.jpg') no-repeat top center; */
  /* background:rgba(0, 0, 0, 0.5); */
  position: absolute;
  z-index: 1;
  height: 450px;
  top: 0;
  left: 0;
}
.testimoni .banner img {
  height: 450px;
  object-fit: cover;
}
.testimoni .description {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  padding: 50px 0 0;
}
.testimoni .description h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 169%;
  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
}
.testimoni .description h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 169%;
  margin: 50px 0 30px;
  text-shadow: 1px 1px 1px #666;
}
.testimoni hr {
  width: 45px;
  margin: auto;
}
.testimoni .profile {
  display: flex;
}
.testimoni .profile .foto {
  margin: 0 30px 0 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.testimoni .profile .foto img {
  width: 100%;
  height: 70px;
  object-fit: cover;
}
.testimoni .profile .user {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
}
.testimoni .profile .user .nama {
  font-weight: 700;
  color: #000;
}

.modal-detail-mobil {
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  z-index: 669;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  place-content: center;
}
.modal-detail-mobil .content {
  width: 990px;
  height: 600px;
  background: white;
  padding: 20px;
  border: 0.5px solid #afafaf;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.modal-detail-mobil .content nav {
  /*text-align: right;*/
}
.modal-detail-mobil .content .detail {
  display: flex;
}
.item-q {
  margin: 10px;
  padding: 0 20px;
  width: 500px;
  height: 535px;
  flex-shrink: 0;
}
.item-q .foto {
  height: 300px;
}
.item-q .foto img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
.item-q nav {
  display: flex;
  margin: 5px 0 0;
}
.item-q nav button {
  background: transparent;
  border: 1px solid var(--background-cokelat-muda);
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px;
  color: var(--background-cokelat-muda);
  cursor: pointer;
}
.item-q nav button:first-child {
  color: #fff;
  background: var(--background-cokelat-muda);
}
.item-q nav button:hover {
  margin-top: 1px;
  border-bottom-width: 3px;
}
.item-q nav button:active {
  margin-top: 3px;
  border-bottom-width: 1px;
}
.item-q .desc {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.item-q .desc .merek {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.item-q .desc .label-harga {
  color: var(--light-color);
  font-weight: 300;
  font-size: 18px;
}
.item-q .desc .harga {
  font-weight: 700;
  font-size: 40px;
  color: #3c3c3b;
  line-height: 50px;
}
.item-q .desc p {
  color: var(--light-color);
  font-weight: 300;
  font-size: 12px;
  padding: 0 50px;
}
.modal-detail-mobil .item-d {
  display: flex;
  flex-direction: column;
  height: 460px;
}
.modal-detail-mobil .item-d h3 {
  color: var(--background-cokelat-muda);
  border-bottom: 1px solid var(--background-cokelat-muda);
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0;
  margin: 0 0 20px;
}
.modal-detail-mobil ul,
.modal-detail-mobil ol {
  padding-left: 15px;
}
.modal-detail-mobil ol {
  padding-top: 10px;
}
.modal-detail-mobil .item-d h4 {
  font-size: 21px;
  color: #525252;
}
.modal-detail-mobil .item-d h5 {
  font-size: 14px;
  color: #525252;
}
.modal-detail-mobil .item-d p {
  color: var(--light-color);
  font-weight: 300;
  font-size: 12px;
}
.modal-detail-mobil .item-d li {
  margin-bottom: 15px;
}
.modal-detail-mobil .item-d nav {
  margin-top: auto;
}
.modal-detail-mobil .item-d nav button {
  background: transparent;
  border: 1px solid var(--background-cokelat-muda);
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px;
  color: var(--background-cokelat-muda);
  cursor: pointer;
  width: 100px;
}
.modal-detail-mobil .feature {
  font-size: 18px;
  font-weight: 300;
  color: var(--light-color);
}
.modal-detail-mobil .close {
  text-align: right;
}
.modal-detail-mobil .close button {
  background: transparent;
  padding: 5px;
  border: none;
  cursor: pointer;
}

@media (max-width: 1300px) {
  .container {
    width: 1024px;
  }
  .car-list .car .item {
    width: 50%;
  }
  .car-list .car .item .item-p {
    margin: 10px;
  }
  .overview .overview-l2 {
    padding: 20px;
  }
}
@media (max-width: 1024px) {
  .modal-detail-mobil .content {
    width: auto;
  }
}
@media (max-width: 990px) {
  header .header-l2 {
    padding: 0 15px;
  }
  .container {
    width: 100%;
  }
  footer {
    padding: 30px 15px;
    font-size: 16px;
  }
  footer .logo {
    margin: 0;
  }
  footer .footer-l2 {
    flex-direction: column;
  }
  footer .footer-l2 .alamat {
    text-align: center;
    margin: 30px 0 0;
  }
  footer .footer-l2 .copyrights {
    width: auto;
    text-align: center;
    margin-top: 30px;
  }
  .services,
  .car-list {
    padding: 20px;
  }
  .heading {
    text-align: center;
    border-bottom: 1px solid var(--main-color);
  }
  .services .service-l2 h2:after {
    display: none;
  }
  .car-list h2 {
    text-align: center;
  }
  .car-list .category {
    text-align: center;
  }
  .overview .about {
    width: 400px;
  }
  .modal-detail-mobil .content .detail .item-q,
  .modal-detail-mobil .content .detail .item-d {
    width: 50%;
  }
}
@media (max-width: 767px) {
  header {
    background: white;
    position: relative;
  }
  header .logo img {
    width: 50%;
  }
  header .header-l2 {
    padding: 0 15px;
  }
  header .menu {
    display: none;
  }
  header .burger {
    display: flex;
  }
  .home .banner {
    height: 225px;
  }
  .home .banner img {
    height: 225px;
  }
  .layanan {
    flex-direction: column;
  }
  .layanan .list {
    border-bottom: 0.5px solid #efefef;
    width: 100%;
    justify-content: center;
  }
  .overview {
    padding: 50px 20px 30px;
  }
  .overview .about {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
  }
  .overview .overview-l2 {
    flex-direction: column;
  }
  .overview .about .about-l2 .desc {
    padding: 20px;
  }
  .testimoni {
    height: auto;
  }
  .testimoni .description {
    position: relative;
  }
  .testimoni .list .item {
    width: auto;
  }
  .testimoni .description h4 {
    margin: 0;
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .wa {
    width: 100%;
    right: 0;
    bottom: 0;
  }
  .services .service-l2 .list li {
    width: 50%;
    margin: 0;
  }
  .car-list .car .item {
    width: 100%;
  }
  .overview .overview-l2 {
    padding: 0;
  }
  .wa-ig {
    padding: 10px 30px;
    flex-direction: column;
    justify-content: center;
  }
  .wa-ig a {
    margin: 5px 0;
    justify-content: center;
  }
  footer {
    padding-bottom: 170px;
  }
  .modal-detail-mobil .content {
    height: auto;
  }
  .modal-detail-mobil .content .detail {
    flex-direction: column;
  }
  .modal-detail-mobil .content .detail .item-q,
  .modal-detail-mobil .content .detail .item-d,
  .item-q .desc .merek {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
  .item-q .foto {
    text-align: center;
    height: 230px;
    overflow: hidden;
  }
  .item-q .foto img {
    width: 70%;
  }
  .car-list h2 {
    font-size: 30px;
  }
  .car-list .category button {
    margin: 5px;
  }
}
@media (max-width: 320px) {
}
